'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * A global state manager for React.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * @author Rodrigo Cesar de Freitas Dias <rcesar@gmail.com>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * @license MIT
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * @see https://github.com/rodrigocfd/react-app-state
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

exports.subscribe = subscribe;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function subscribe() {
	for (var _len = arguments.length, stores = Array(_len), _key = 0; _key < _len; _key++) {
		stores[_key] = arguments[_key];
	}

	return function (WrappedComponent) {
		var FinalComponent = WrappedComponent;
		var _iteratorNormalCompletion = true;
		var _didIteratorError = false;
		var _iteratorError = undefined;

		try {
			for (var _iterator = stores[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
				var store = _step.value;

				FinalComponent = store.subscribe(FinalComponent);
			}
		} catch (err) {
			_didIteratorError = true;
			_iteratorError = err;
		} finally {
			try {
				if (!_iteratorNormalCompletion && _iterator.return) {
					_iterator.return();
				}
			} finally {
				if (_didIteratorError) {
					throw _iteratorError;
				}
			}
		}

		return FinalComponent;
	};
}

var AppState = function () {
	function AppState(propsAndValues) {
		_classCallCheck(this, AppState);

		this._propsAndValues = propsAndValues; // each prop with initial value
		this._components = []; // each subscribed component
		this._instanceCount = 0; // how many instances to call setState()
	}

	_createClass(AppState, [{
		key: 'subscribe',
		value: function subscribe(WrappedComponent) {
			var component = {
				instances: {}, // AppStateSubscriber instances
				nextInstanceId: 0
			};
			this._components.push(component);
			var THIS = this; // so we can access it inside class below

			return function (_React$Component) {
				_inherits(AppStateSubscriber, _React$Component);

				function AppStateSubscriber(props) {
					_classCallCheck(this, AppStateSubscriber);

					var _this = _possibleConstructorReturn(this, (AppStateSubscriber.__proto__ || Object.getPrototypeOf(AppStateSubscriber)).call(this, props));

					_this._instanceId = 'in' + component.nextInstanceId++;
					component.instances[_this._instanceId] = _this; // store instance using ID as key
					++THIS._instanceCount;
					_this.state = _extends({}, THIS._propsAndValues); // receive current values right away
					return _this;
				}

				_createClass(AppStateSubscriber, [{
					key: 'componentWillUnmount',
					value: function componentWillUnmount() {
						--THIS._instanceCount;
						delete component.instances[this._instanceId]; // so set() won't dispatch to inexistent instance
					}
				}, {
					key: 'render',
					value: function render() {
						return _react2.default.createElement(WrappedComponent, _extends({}, this.props, this.state));
					}
				}]);

				return AppStateSubscriber;
			}(_react2.default.Component);
		}
	}, {
		key: 'get',
		value: function get(propName) {
			if (!this._propsAndValues.hasOwnProperty(propName)) {
				throw new Error('Retrieving an inexistent prop: ' + propName);
			}
			return this._propsAndValues[propName];
		}
	}, {
		key: 'set',
		value: function set(propsAndValues, callback) {
			var _iteratorNormalCompletion2 = true;
			var _didIteratorError2 = false;
			var _iteratorError2 = undefined;

			try {
				for (var _iterator2 = Object.keys(propsAndValues)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
					var propName = _step2.value;

					if (!this._propsAndValues.hasOwnProperty(propName)) {
						throw new Error('Updating an inexistent prop: ' + propName);
					}
				}
			} catch (err) {
				_didIteratorError2 = true;
				_iteratorError2 = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion2 && _iterator2.return) {
						_iterator2.return();
					}
				} finally {
					if (_didIteratorError2) {
						throw _iteratorError2;
					}
				}
			}

			this._propsAndValues = _extends({}, this._propsAndValues, propsAndValues); // update internal store

			var asyncCallback = null;
			if (callback) {
				var instanceCount = this._instanceCount;
				asyncCallback = function asyncCallback() {
					if (--instanceCount === 0) {
						callback(); // user callback is fired after last setState() returns
					}
				};
			}

			var _iteratorNormalCompletion3 = true;
			var _didIteratorError3 = false;
			var _iteratorError3 = undefined;

			try {
				for (var _iterator3 = this._components[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
					var component = _step3.value;
					var _iteratorNormalCompletion4 = true;
					var _didIteratorError4 = false;
					var _iteratorError4 = undefined;

					try {
						for (var _iterator4 = Object.keys(component.instances)[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
							var instanceId = _step4.value;

							component.instances[instanceId].setState(_extends({}, propsAndValues), asyncCallback); // AppStateSubscriber.setState()
						}
					} catch (err) {
						_didIteratorError4 = true;
						_iteratorError4 = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion4 && _iterator4.return) {
								_iterator4.return();
							}
						} finally {
							if (_didIteratorError4) {
								throw _iteratorError4;
							}
						}
					}
				}
			} catch (err) {
				_didIteratorError3 = true;
				_iteratorError3 = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion3 && _iterator3.return) {
						_iterator3.return();
					}
				} finally {
					if (_didIteratorError3) {
						throw _iteratorError3;
					}
				}
			}
		}
	}]);

	return AppState;
}();

exports.default = AppState;